import React, { useState, useEffect } from "react";
import { Link, useStaticQuery, graphql  } from "gatsby";
import CookieConsent from "react-cookie-consent";

// Images
import MenuIcon from "../images/icon/menu.svg";
import LogoLight from "../images/root-logo-light.svg";
import LogoDark from "../images/root-logo-dark.svg";
import GitHubIcon from "../images/icon/github.svg";
import TwitterIcon from "../images/icon/twitter.svg";

export default function Header({ type = "dark", showLabel = false, labelType = "docs", labelText = "Docs" }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    if(menuVisible === true) {
      document.querySelector(".site-header__navigation ul").firstChild.focus();
    }
  }, [menuVisible]);

  function handleMenuClick() {
    setMenuVisible(!menuVisible);
  }

  return (
    <>
      <CookieConsent
        containerClasses="cookie-consent"
        contentClasses="cookie-consent__body"
        buttonWrapperClasses="cookie-consent__btns"
        declineButtonClasses="btn btn--decline btn--rounded"
        buttonClasses="btn btn--primary btn--rounded"
        enableDeclineButton
        location="none"
        buttonText="Accept"
        declineButtonText="Decline"
        setDeclineCookie={false}
        disableStyles={true}
        cookieName="root-gdpr-cookies"
        onAccept={() => {
          window.location.reload();
        }}
        onDecline={() => {
          document.cookie = "root-gdpr-cookies=false; expires=0; path=/";
        }}
      >
        <p>We use cookies to track analytics data. It helps us to understand how our site is used.</p>
      </CookieConsent>

      <a className="btn btn--primary skip-link" href="#content">Skip to content</a>

      <header className={`site-header site-header--${type}`}>
        <div className="container">
          <div className="site-header__inner">
            <div className="site-header__logo">
              <Link to="/" title={site.siteMetadata.title}>
                {type === 'light' ? <LogoLight /> : <LogoDark />}
              </Link>
              {showLabel && <span className={`label label--${labelType}`}>{labelText}</span>}
            </div>
            <nav className="site-header__navigation">
              <button className="btn btn--primary btn--icon site-header__toggle" onClick={handleMenuClick} aria-controls="primary-menu" aria-expanded={menuVisible ? "true" : "false"} aria-label={menuVisible ? "Close navigation" : "Open navigation"}>
                <MenuIcon className="btn__icon" />
              </button>
              <ul>
                <li><Link to="/docs/core-concepts">Docs</Link></li>
                <li><a href="https://github.com/conedevelopment/root/releases">Releases</a></li>
              </ul>
            </nav>
            <div className="site-header__actions">
              <a href="https://github.com/conedevelopment/root" aria-label="GitHub" className="btn btn--primary btn--icon btn--github">
                <GitHubIcon className="btn__icon" />
              </a>
              <a href="https://twitter.com/conedevelopment" aria-label="GitHub" className="btn btn--primary btn--icon btn--twitter">
                <TwitterIcon className="btn__icon" />
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
