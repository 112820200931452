import React from "react";
import { Link, useStaticQuery, graphql  } from "gatsby";

// Images
import Logo from "../images/root-logo-dark.svg";
import ConeLogo from "../images/cone-logo.svg";

export default function Footer() {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          version
        }
      }
    }
  `);

  return (
    <footer className="site-footer">
      <div className="container">
        <div className="site-footer__top">
          <div className="site-footer__col">
            <Link className="site-footer__logo" to="/" title={site.siteMetadata.title}>
              <Logo />
            </Link>
          </div>
          <div className="site-footer__col">
            <h3 className="site-footer__title" id="site-footer-title-01">Root</h3>
            <ul className="site-footer__navigation" aria-labelledby="site-footer-title-01">
              <li><Link to="/docs/core-concepts">Core Concepts</Link></li>
              <li><Link to="/docs/dashboard">Dashboard</Link></li>
              <li><Link to="/docs/resources">Resources</Link></li>
              <li><Link to="/docs/assets">Assets</Link></li>
            </ul>
          </div>
          <div className="site-footer__col">
            <h3 className="site-footer__title">Bazar</h3>
            <ul className="site-footer__navigation">
            <li><Link to="/docs/bazar/core-concepts">Core Concepts</Link></li>
            <li><Link to="/docs/bazar/installation">Installation</Link></li>
            <li><Link to="/docs/bazar/admin">Admin</Link></li>
            <li><Link to="/docs/bazar/cart">Cart</Link></li>
            </ul>
          </div>
          <div className="site-footer__col">
            <h3 className="site-footer__title">More From Us</h3>
            <ul className="site-footer__navigation">
              <li><a href="https://conedevelopment.com/services/">Services</a></li>
              <li><a href="https://pineco.de/">Pine - A web development blog</a></li>
              <li><a href="https://bazar.conedevelopment.com/">Spruce CSS - Another CSS Framework</a></li>
            </ul>
          </div>
        </div>
        <div className="site-footer__bottom">
          <div className="site-footer__information-wrapper">
            <a href="https://conedevelopment.com/" className="site-footer__cone-logo" aria-label="Cone"><ConeLogo /></a>
            <p className="site-footer__information">Code is licensed under <a href="https://github.com/conedevelopment/sprucecss/blob/master/LICENSE/">MIT</a>, docs is <a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>. - Version <strong>{site.siteMetadata.version}</strong> - <Link to="/privacy-policy">Privacy Policy</Link></p>
          </div>
        </div>
      </div>
    </footer>
  );
}
